import React from 'react';
import {formatDate} from "date-fns/format";
import {useTranslation} from "react-i18next";
import clsx from 'clsx';

export interface NumberCellProps {
  value?: number;
  currency?: string;
  direction?: -1|1;
}

function NumberCell({value, currency, direction}: NumberCellProps) {
  const {t} = useTranslation()
  if (!value) return null;
  if (direction) {
    return (
      <span className={clsx({'text-success': direction === 1, 'text-danger': direction === -1})}>{direction === 1 ? '+' : '-'}{t('intlCurrency', {
        value,
        currency,
      })}</span>
    )
  }
  return currency ? t('intlCurrency', {value, currency}) : t('intlNumber', {value});
}

export default NumberCell;
