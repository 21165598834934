import React from 'react';
import {formatDate} from "date-fns/format";

function DateCell({value, format = 'PPpp'}: {value?: string; format?: string}) {
  if (!value) {
    return null;
  }
  return <span>{formatDate(value, 'PP')}{format.indexOf('pp') > 0 && <><br/>{formatDate(value, 'pp')}</>}</span>;
}

export default DateCell;
