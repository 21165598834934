import {ColumnInstance} from 'react-table'

export interface CustomHeaderColumnProps<T extends object> {
  column: ColumnInstance<T>;
}

function CustomHeaderColumn<T extends object>({column}: CustomHeaderColumnProps<T>) {
  const {key, ...props} = column.getHeaderProps();
  return (
    <>
      {column.Header && typeof column.Header === 'string' ? <th {...props}>{column.render('Header')}</th> : column.render('Header')}
    </>
  )
}

export default CustomHeaderColumn
