import React, {useEffect, useState} from 'react';
import {initialQueryState, KTIcon} from "../../helpers";
import {useTranslation} from "react-i18next";
import {useQueryResponse} from "./QueryResponseProvider.tsx";
import {useQueryRequest} from "./QueryRequestProvider.tsx";
import {MenuComponent} from "../../assets/ts/components";
import clsx from "clsx";
import Flatpickr from "react-flatpickr";

interface ListFilterProps {
  filters: Array<{ title?: string; name: string; options: Array<{ value: string; label: string }> }>;
}

function DateRange() {
  return (
    <Flatpickr
      // value={filter[f.name]}
      onChange={([startDate, endDate]) => {
        if (startDate && endDate) {
          // setFilter({...filter, [f.name]: [startDate, endDate]})
          // setDateSelected([startDate, endDate]);
          // fetchData([startDate, endDate])
        }
      }}
      options={{
        mode: "range"
      }}
      // className={clsx('form-control form-control-solid w-120px', {'me-2': index < filters.length - 1})}
      // placeholder={t('Pick date')}
    />
  )
}

function ListFilter({filters}: ListFilterProps) {
  const {t} = useTranslation()
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [filter, setFilter] = useState({})

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setFilter({})
    updateState({filter: {}, ...initialQueryState})
  }

  const filterData = () => {
    updateState({
      filter,
      ...initialQueryState,
    })
  }

  if (!filters || filters.length == 0) {
    return null;
  }

  return (
    <>
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTIcon iconName='filter' className='fs-2'/>
        {t('Filter')}
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-gray-900 fw-bolder'>{t('Filter Options')}</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {filters?.map((f, index) => {
            let input = null;
            switch (f.type) {
              case 'date':
                input = (
                  <DateRange />
                )
                break;
              default:
                input = (
                  <select
                    key={f.name}
                    className={clsx('form-select form-select-solid fw-bolder', {'me-2': index < filters.length - 1})}
                    data-kt-select2='true'
                    data-placeholder={f.title}
                    data-allow-clear='true'
                    data-kt-user-table-filter={f.name}
                    data-hide-search='true'
                    onChange={(e) => setFilter({...filter, [f.name]: e.target.value})}
                    value={filter[f.name]}
                  >
                    <option></option>
                    {f.options.map(o => <option value={o.value} key={o.value}>{o.label}</option>)}
                  </select>
                )
            }

            return (
              <div className='mb-10' key={`filter-input-${index}`}>
                <label className='form-label fs-6 fw-bold'>{f.title}:</label>
                {input}
              </div>
            )
          })}

          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              {t('Reset')}
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              {t('Apply')}
            </button>
          </div>
          {/* end::Actions */}
        </div>
      </div>
    </>
  );
}

export default ListFilter;
